<template>
	<v-sheet class="inventory" id="inventory" style="height: calc(100vh - 88px)">
		<CreateDialog :dialog="createDialog" v-on:close="(createDialog = false), clearLocalData()">
			<template v-slot:title>
				<span>{{ pageTitle() }} </span>
				<span class="orange--text text--darken-4 barcode-font-size">#{{ inventory.barcode }}</span>
			</template>
			<template v-slot:body>
				<template v-if="!barcodeLoading">
					<v-form
						ref="inventoryForm"
						v-model.trim="formValid"
						lazy-validation
						v-on:submit.stop.prevent="updateOrCreate"
					>
						<v-row>
							<v-col md="9">
								<perfect-scrollbar
									:options="{ suppressScrollX: true }"
									class="scroll custom-box-top-inner-shadow"
									style="max-height: calc(100vh - 140px); position: relative"
								>
									<v-row v-if="false">
										<v-col md="12" class="py-0">
											<p class="middle-header-background mb-4 px-4">
												Core Information<br />
												<span>
													<v-icon small>mdi-progress-question</v-icon> Specify the primary description for your
													perishables</span
												>
											</p>
										</v-col>
									</v-row>
									<!-- <v-row class="px-4">
										<v-col md="3" class="my-auto py-0">
											<label for="name" class="btx-label mt-2">Product Type</label>
										</v-col>
										<v-col md="9" class="py-0">
											<v-radio-group
												v-on:change="getSerialNumber(), savetoLocal()"
												class="ma-0"
												hide-details
												mandatory
												row
												v-model="inventory.product_type"
											>
												<v-radio label="Piping" value="piping"></v-radio>
												<v-radio label="Other items" value="other items"></v-radio>
											</v-radio-group>
										</v-col>
									</v-row> -->
									<v-row class="px-4">
										<v-col md="3" class="my-auto py-0">
											<label for="serial-number" class="btx-label mt-2 required">Serial Number</label>
										</v-col>
										<v-col md="3" class="py-0">
											<TextInput
												hide-details
												:disabled="uuid ? true : false"
												:loading="pageLoading"
												id="serial-number"
												placeholder="Serial Number"
												:rules="[
													vrules.required(inventory.serial_number, 'Serial Number'),
													serialNumberError ? false : true,
												]"
												:class="{
													required: !inventory.serial_number,
												}"
												v-model="inventory.serial_number"
												v-on:change="
													validateSerialNumber(
														inventory.serial_number,
														inventory.product_type == 'piping' ? 'piping' : 'other-items'
													),
														savetoLocal()
												"
											></TextInput>
											<span v-if="serialNumberError" class="red--text text--darken-1 font-small">{{
												serialNumberError
											}}</span>
										</v-col>
										<v-col md="3" class="my-auto py-0 text-right">
											<label for="quote-ref-no" class="btx-label mt-2">Quote Ref No.</label>
										</v-col>
										<v-col md="3" class="py-0">
											<TextInput
												hide-details
												:disabled="pageLoading"
												:loading="pageLoading"
												id="quote-ref-no"
												placeholder="Quote Ref No."
												v-model="inventory.quote_ref_no"
												@change="savetoLocal()"
											></TextInput>
										</v-col>
										<v-col md="3" class="my-auto py-0">
											<label for="name" class="btx-label mt-2 required">Name</label>
										</v-col>
										<v-col md="9" class="py-0">
											<TextInput
												hide-details
												:disabled="pageLoading"
												:loading="pageLoading"
												id="name"
												:rules="[vrules.required(inventory.name, 'Name')]"
												:class="{
													required: !inventory.name,
												}"
												placeholder="Name"
												v-model="inventory.name"
												@change="savetoLocal()"
											></TextInput>
										</v-col>
										<v-col md="3" class="py-0">
											<label for="description" class="btx-label mt-2">Description</label>
										</v-col>
										<v-col md="9" class="py-0">
											<TextAreaInput
												hide-details
												:disabled="pageLoading"
												:loading="pageLoading"
												id="description"
												placeholder="Description"
												v-model="inventory.description"
												@change="savetoLocal()"
											></TextAreaInput>
										</v-col>
										<v-col md="3" class="my-auto py-0">
											<label for="material-standard" class="btx-label mt-2">Material Standard</label>
										</v-col>
										<v-col md="3" class="py-0">
											<TextInput
												hide-details
												:disabled="pageLoading"
												:loading="pageLoading"
												id="material-standard"
												placeholder="Material Standard"
												v-model="inventory.material_standard"
												@change="savetoLocal()"
											></TextInput>
										</v-col>
										<v-col md="3" class="my-auto py-0 text-right">
											<label for="dimension-standard" class="btx-label mt-2">Dimension Standard</label>
										</v-col>
										<v-col md="3" class="py-0">
											<TextInput
												hide-details
												:disabled="pageLoading"
												:loading="pageLoading"
												id="dimension-standard"
												placeholder="Dimension Standard"
												v-model="inventory.diamension_standard"
												@change="savetoLocal()"
											></TextInput>
										</v-col>
										<v-col md="3" class="my-auto py-0">
											<label for="reorder-level" class="btx-label mt-2">Reorder Level</label>
										</v-col>
										<v-col md="3" class="py-0">
											<QuantityInput
												hide-details
												:disabled="pageLoading"
												:loading="pageLoading"
												type="number"
												id="reorder-level"
												placeholder="Reorder Level"
												v-model.number="inventory.reorder_level"
												@change="savetoLocal()"
											>
											</QuantityInput>
										</v-col>
										<v-col md="3" class="my-auto py-0 text-right">
											<label for="unit-111" class="btx-label mt-2">Unit</label>
										</v-col>
										<v-col md="3" class="py-0">
											<SelectUnit
												hide-details
												:disabled="pageLoading"
												:loading="pageLoading"
												id="unit"
												placeholder="Unit"
												v-model="inventory.unit"
												append-outer-icon="mdi-cog"
											></SelectUnit>
										</v-col>
										<v-col md="3" class="my-auto py-0">
											<label for="manufacturer" class="btx-label mt-2">Manufacturer</label>
										</v-col>
										<v-col md="3" class="py-0">
											<SelectManufacturer
												hide-details
												:disabled="pageLoading"
												:loading="pageLoading"
												id="mnufacturer"
												placeholder="Manufacturer"
												v-model="inventory.manufacturer"
												append-outer-icon="mdi-cog"
												@change="savetoLocal()"
											></SelectManufacturer>
											<TextInput
												v-if="false"
												hide-details
												:disabled="pageLoading"
												:loading="pageLoading"
												id="manufacturer"
												placeholder="Manufacturer"
												v-model="inventory.manufacturer"
												@change="savetoLocal()"
											></TextInput>
										</v-col>
										
										<v-col md="3" class="my-auto py-0 text-right">
											<label for="group" class="btx-label mt-2 required">Category</label>
										</v-col>

										<v-col md="3" class="py-0">
											<AutoCompleteInput
												hide-details
												:items="groupList"
												:disabled="pageLoading"
												:loading="pageLoading"
												id="group"
												:rules="[vrules.required(inventory.group, 'Category')]"
												:class="{
													required: !inventory.group,
												}"
												placeholder="Category"
												v-model="inventory.group"
												append-outer-icon="mdi-cog"
												v-on:click:append-outer="manageCategoryDialog = true"
												@change="savetoLocal()"
											></AutoCompleteInput>
										</v-col>
										<v-col md="3" class="my-auto py-0">
											<label for="unit-price-currency" class="btx-label mt-2">Unit Price Currency</label>
										</v-col>
										<v-col md="3" class="my-auto py-0">
											<AutoCompleteInput
												hide-details
												:items="currencyList"
												:disabled="pageLoading"
												:loading="pageLoading"
												id="unit-price-currency"
												class=""
												placeholder="Unit Price Currency"
												v-model="inventory.unit_price_currency"
												@change="savetoLocal()"
											>
											</AutoCompleteInput>
										</v-col>
										<v-col md="3" class="my-auto py-0 text-right" v-if="!uuid">
											<label for="initial-stock" class="btx-label mt-2">Initial Stock</label>
										</v-col>
										<v-col md="3" class="my-auto py-0" v-if="!uuid">
											<QuantityInput
												hide-details
												:disabled="pageLoading"
												:loading="pageLoading"
												id="initial-stock"
												type="number"
												class=""
												placeholder="Initial Stock"
												v-model.number="inventory.initial_stock"
												@change="savetoLocal()"
											>
											</QuantityInput>
										</v-col>
										<v-col md="3" class="py-0" v-if="false">
											<label for="remark" class="btx-label mt-2 required">Remark</label>
										</v-col>
										<v-col md="9" class="py-0" v-if="false">
											<TextAreaInput
												:disabled="pageLoading"
												:loading="pageLoading"
												id="remark"
												placeholder="Remark"
												v-model="inventory.remark"
												:rules="[vrules.required(inventory.remark, 'Remark')]"
												:class="{
													required: !inventory.remark,
												}"
											></TextAreaInput>
										</v-col>
									</v-row>
									<v-row v-if="!uuid">
										<v-col md="12" class="py-0">
											<div class="middle-header-background mt-4 mt-7 px-4 d-flex align-center">
												<p>
													Price Information<br />
													<span>
														<v-icon small>mdi-progress-question</v-icon> Specify the unit price, selling cost and
														discount price details for your product</span
													>
												</p>
												<v-spacer></v-spacer>
												<v-btn
													class="white--text mr-2"
													depressed
													color="blue darken-4"
													tile
													:disabled="isDisable"
													@click="addPrice()"
												>
													<v-icon left>mdi-plus</v-icon>
													Add New Price
												</v-btn>
											</div>
										</v-col>
									</v-row>
									<v-col md="12" v-if="!uuid && priceList && priceList.length">
										<v-row class="mt-1">
											<!-- <v-col class="py-0">
												<label for="fw-500" class="btx-label mt-2">Unit Price</label>
											</v-col> -->
											<v-col class="py-0">
												<label class="btx-label mt-2">Selling Price/Unit Price</label>
											</v-col>
											<v-col class="py-0">
												<label class="btx-label mt-2">Discounted Price</label>
											</v-col>
											<v-col class="py-0">
												<label class="btx-label mt-2">Start Date</label>
											</v-col>
											<v-col class="py-0">
												<label class="btx-label mt-2">End Date</label>
											</v-col>
											<v-col md="1" class="py-0">
												<label class="btx-label mt-2"></label>
											</v-col>
										</v-row>
										<template>
											<v-row v-for="(row, index) in priceList" :key="index" class="mb-3">
												<!-- <v-col class="py-0">
													<PriceInput
														type="number"
														hide-details
														:disabled="pageLoading"
														:loading="pageLoading"
														placeholder="Unit Price"
														prepend-inner-icon="mdi-currency-usd"
														v-model="row.unit_price"
														class="mt-0"
														:rules="[vrules.required(row.unit_price, 'unit price')]"
														:class="{
															required: !row.unit_price,
														}"
													></PriceInput>
												</v-col> -->
												<v-col class="py-0">
													<PriceInput
														type="number"
														hide-details
														:disabled="pageLoading"
														:loading="pageLoading"
														placeholder="Selling Price/Unit Price"
														prepend-inner-icon="mdi-currency-usd"
														v-model="row.selling_price"
														:rules="[vrules.required(row.selling_price, 'Selling Price/Unit Price')]"
														:class="{
															required: !row.selling_price,
														}"
														class="mt-0"
													></PriceInput>
												</v-col>
												<v-col class="py-0">
													<PriceInput
														type="number"
														hide-details
														:disabled="pageLoading"
														:loading="pageLoading"
														placeholder="Discount Price"
														@keyup="discountPriceChange(index)"
														prepend-inner-icon="mdi-currency-usd"
														v-model.number="row.discount_price"
														class="mt-0"
													></PriceInput>
												</v-col>
												<v-col class="py-0">
													<DatePicker2
														hide-details
														hide-top-margin
														:disabled="isStartDateDisable(index) || pageLoading"
														:loading="pageLoading"
														placeholder="Start Date"
														:min-date="end_min_date(row.start_date, index)"
														v-model="row.start_date"
														:rules="[vrules.required(row.start_date, 'Start Date')]"
														:class="{
															required: !row.start_date,
														}"
														@change="updateStartDate(row.start_date, index)"
													></DatePicker2>
												</v-col>
												<v-col class="py-0">
													<DatePicker2
														hide-details
														readonly
														:loading="pageLoading"
														:disabled="true"
														hide-top-margin
														placeholder="End Date"
														v-model="row.end_date"
													></DatePicker2>
												</v-col>
												<v-col md="1" class="py-0">
													<v-btn
														v-if="priceList.length == index + 1"
														class="white--text"
														depressed
														color="red darken-4"
														tile
														@click="removePrice(index, row)"
														min-width="30"
														max-width="30"
														width="30"
													>
														<v-icon>mdi-delete</v-icon>
													</v-btn>
												</v-col>
											</v-row>
										</template>
									</v-col>
									<v-row>
										<v-col md="12" class="py-0">
											<p class="middle-header-background my-4 mt-7 px-4">
												Extended Information<br />
												<span>
													<v-icon small>mdi-progress-question</v-icon>
													Specify the weight, length, height, width,
													<template v-if="inventory.product_type == 'piping'">
														diameter and reduced diameter
													</template>
													<template v-else> height and width </template>
													details for your product
												</span>
											</p>
										</v-col>
									</v-row>
									<v-col md="12" class="my-auto py-0">
										<v-row>
											<v-col md="3" class="my-auto py-0">
												<label for="weight" class="btx-label mt-2">Weight</label>
												<QuantityInput
													hide-details
													:disabled="pageLoading"
													:loading="pageLoading"
													id="weight"
													type="number"
													suffix="kg"
													class="mt-0"
													placeholder="weight"
													v-model.number="inventory.weight"
													@change="savetoLocal()"
												>
												</QuantityInput>
											</v-col>
											<v-col md="3" class="my-auto py-0">
												<label for="length" class="btx-label mt-2">Length</label>
												<QuantityInput
													hide-details
													:disabled="pageLoading"
													:loading="pageLoading"
													id="length"
													type="number"
													suffix="cm"
													class="mt-0"
													placeholder="Length"
													v-model.number="inventory.length"
													@change="savetoLocal()"
												>
												</QuantityInput>
											</v-col>
											<template>
												<v-col md="3" class="my-auto py-0">
													<label for="diameter" class="btx-label mt-2">Diameter</label>
													<QuantityInput
														hide-details
														:disabled="pageLoading"
														:loading="pageLoading"
														id="diameter"
														type="number"
														suffix="cm"
														class="mt-0"
														placeholder="Diameter"
														v-model.number="inventory.diameter"
														@change="savetoLocal()"
													>
													</QuantityInput>
												</v-col>
												<v-col md="3" class="my-auto py-0">
													<label for="reduced-diameter" class="btx-label mt-2">Reduced Diameter</label>
													<QuantityInput
														hide-details
														:disabled="pageLoading"
														:loading="pageLoading"
														id="reduced-diameter"
														type="number"
														suffix="cm"
														class="mt-0"
														placeholder="Reduced Diameter"
														v-model.number="inventory.reduced_diameter"
														@change="savetoLocal()"
													>
													</QuantityInput>
												</v-col>
											</template>
											<template>
												<v-col md="3" class="my-auto py-0">
													<label for="height" class="btx-label mt-2">Height</label>
													<QuantityInput
														hide-details
														:disabled="pageLoading"
														:loading="pageLoading"
														id="height"
														class="mt-0"
														type="number"
														suffix="cm"
														placeholder="Height"
														v-model.number="inventory.height"
														@change="savetoLocal()"
													></QuantityInput>
												</v-col>
												<v-col md="3" class="my-auto py-0">
													<label for="width" class="btx-label mt-2">Width</label>
													<QuantityInput
														hide-details
														:disabled="pageLoading"
														:loading="pageLoading"
														id="width"
														class="mt-0"
														suffix="cm"
														type="number"
														placeholder="Width"
														v-model.number="inventory.width"
														@change="savetoLocal()"
													></QuantityInput>
												</v-col>
											</template>
											<v-col md="12" class="py-0">
												<div class="middle-header-background mt-4 mt-7 px-4 d-flex align-center">
													<p>
														Supplier Information<br />
														<span>
															<v-icon small>mdi-progress-question</v-icon> Specify the supplier, supplier code and
															price details for your product</span
														>
													</p>
													<v-spacer></v-spacer>
													<v-btn
														class="white--text mr-2"
														depressed
														color="blue darken-4"
														tile
														@click="addSupplier()"
													>
														<v-icon left>mdi-plus</v-icon>
														Add Supplier
													</v-btn>
												</div>
											</v-col>
											<v-col md="12">
												<v-row class="mt-1" v-if="suppliers && suppliers.length">
													<v-col md="5" class="py-0">
														<label for="supplier fw-500" class="btx-label mt-2">Supplier</label>
													</v-col>
													<v-col md="3" class="py-0">
														<label for="supplier-part-number" class="btx-label mt-2">Supplier Code</label>
													</v-col>
													<v-col md="3" class="py-0">
														<label for="supplier-price" class="btx-label mt-2">Purchasing Price</label>
													</v-col>
													<v-col class="py-0">
														<label for="" class="btx-label mt-2"></label>
													</v-col>
												</v-row>
												<template>
													<v-row v-for="(row, index) in suppliers" :key="index" class="mb-3">
														<v-col md="5" class="py-0">
															<AutoCompleteInput
																hide-details
																:items="supplierList(suppliers[index].supplier_id)"
																:disabled="pageLoading"
																:loading="pageLoading"
																id="supplier"
																autofocus
																placeholder="Supplier"
																v-model="suppliers[index].supplier_id"
																:rules="[vrules.required(suppliers[index].supplier_id, 'Supplier')]"
																:class="{
																	required: !suppliers[index].supplier_id,
																}"
																@change="saveSupplierToLocal()"
																class="mt-0"
															>
															</AutoCompleteInput>
														</v-col>
														<v-col md="3" class="py-0">
															<TextInput
																hide-details
																:disabled="pageLoading"
																:loading="pageLoading"
																id="supplier-part-number"
																placeholder="Supplier Code"
																v-model="suppliers[index].part_number"
																class="mt-0"
																@change="saveSupplierToLocal()"
															></TextInput>
														</v-col>
														<v-col md="3" class="py-0">
															<PriceInput
																type="number"
																hide-details
																:disabled="pageLoading"
																:loading="pageLoading"
																id="supplier-price"
																placeholder="Purchasing Price"
																prepend-inner-icon="mdi-currency-usd"
																v-model="suppliers[index].price"
																:rules="[vrules.required(suppliers[index].price, 'Purchasing Price')]"
																:class="{
																	required: !suppliers[index].price,
																}"
																class="mt-0"
																@change="saveSupplierToLocal()"
															></PriceInput>
														</v-col>
														<v-col class="py-0">
															<v-btn
																class="white--text"
																depressed
																color="red darken-4"
																tile
																@click="deleteConfirm(index,row)"
																min-width="30"
																max-width="30"
																width="30"
															><!--@click="removeSupplier(index, row), savetoLocal()"-->
																<v-icon>mdi-delete</v-icon>
															</v-btn>
														</v-col>
													</v-row>
												</template>
											</v-col>
											<template v-if="false">
												<v-col md="4" class="my-auto py-0">
													<label for="purchased-on" class="btx-label mt-2 required">Purchased On</label>
												</v-col>
												<v-col md="9" class="py-0">
													<DatePicker
														hide-details
														clearable
														:disabled="pageLoading"
														:loading="pageLoading"
														id="purchased-on"
														placeholder="Purchased On"
														v-model="inventory.purchased_on"
														:rules="[vrules.required(inventory.purchased_on, 'Purchased On')]"
														:class="{
															required: !inventory.purchased_on,
														}"
													></DatePicker>
												</v-col>
											</template>
										</v-row>
									</v-col>
									<v-row class="px-4" v-if="false">
										<v-col md="4" class="my-auto py-0">
											<label for="price-for-add-stock" class="btx-label mt-2 required"
												>Price For Add Stock
												<TooltipQuestion>
													<template v-slot:text>Default price for adding new stock</template>
												</TooltipQuestion>
											</label>
										</v-col>
										<v-col md="4" class="my-auto py-0">
											<label for="contact" class="btx-label mt-2">Contact for Item</label>
										</v-col>
										<v-col md="9" class="py-0">
											<PhoneInput
												hide-details
												:disabled="pageLoading"
												:loading="pageLoading"
												id="contact"
												placeholder="Contact for Item"
												v-model="inventory.contact"
											>
											</PhoneInput>
										</v-col>
										<v-col md="9" class="py-0">
											<PriceInput
												hide-details
												:disabled="pageLoading"
												:loading="pageLoading"
												id="price-for-add-stock"
												placeholder="Price For Add Stock"
												v-model="inventory.price_add_stock"
												:rules="[vrules.required(inventory.price_add_stock, 'Purchased On')]"
												:class="{
													required: !inventory.price_add_stock,
												}"
											></PriceInput>
										</v-col>
									</v-row>
								</perfect-scrollbar>
							</v-col>
							<v-col md="3">
								<v-row>
									<v-col md="12" class="my-6 py-0 text-center">
										<ImageUpload can-change v-model="inventory.image" :page-loading="pageLoading">
										</ImageUpload>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-form>
				</template>
				<template v-else>
					<div class="mx-20 my-40 text-center">
						<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
					</div>
				</template>
			</template>
			<template v-slot:action>
				<v-btn
					:loading="pageLoading"
					class="white--text mr-2"
					depressed
					color="blue darken-4"
					tile
					:disabled="pageLoading || !formValid"
					@click="updateOrCreate(statusapprove)"
				>
					{{ uuid ? "Update" : "Save" }}
				</v-btn>
			</template>
		</CreateDialog>
		<ManageCategory
			:dialog="manageCategoryDialog"
			:category="groupList"
			v-on:close-dialog="manageCategoryDialog = false"
			v-on:success="getCategories"
		></ManageCategory>

		<Dialog :dialog="deleteDialog" :dialog-width="640">
			<template v-slot:title> Delete Supplier</template>
			<template v-slot:body>
				<v-row class="delete-dialog">
					<v-col md="2" class="py-0 text-right my-auto">
						<span class="svg-icon svg-icon-lg delete-confirm-icon">
							<!--begin::Svg Icon-->
							<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
							<!--end::Svg Icon-->
						</span>
					</v-col>
					<v-col md="10" class="py-0 my-auto">
						<p class="btx-p m-0">
							<span class="text-capitalize"> </span>
							<span class="red--text font-bolder lighten-1">#This will delete all line items of this supplier from draft and rejected Purchase Orders. 
								<br/><br/>Are you sure about deleting it?</span>
							
						</p>
					</v-col>
					
				</v-row>
			</template>
			<!-- :loading="deleteButton"
				:disabled="deleteButton" -->

			<template v-slot:action>
				<v-btn
					class="white--text"
					
					depressed
					color="red lighten-1"
					tile
					v-on:click="removeSupplier(deleteIndex,deleteRow)"
				>
					Yes! Delete
				</v-btn>
				<v-btn depressed tile v-on:click="deleteClear"> No, Close </v-btn>
			</template>
		</Dialog>

	</v-sheet>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import {
	CreateInventory,
	UpdateInventory,
	GetInventory,
	RemoveSuppliers,
} from "@/core/lib/inventory.lib";
import { ValidateSerialNumber, GenrateSerialNumber } from "@/core/lib/common.lib";
// import { GenrateBarcode } from "@/core/lib/setting.lib";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TextInput from "@/view/components/TextInput";
import CreateDialog from "@/view/components/CreateDialog";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import PhoneInput from "@/view/components/Phone";
import SelectUnit from "@/view/components/SelectUnit";
import PriceInput from "@/view/components/PriceInput";
import QuantityInput from "@/view/components/QuantityInput";
import ImageUpload from "@/view/components/ImageUpload";
import TooltipQuestion from "@/view/components/TooltipQuestion";
import { filter, cloneDeep, /* findIndex, */ find } from "lodash";
import DatePicker from "@/view/components/DatePicker";
import DatePicker2 from "@/view/components/DatePicker2";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";
import ManageCategory from "@/view/components/Manage-Category";
import { saveData, getData, deleteData } from "@/core/services/local.service";
import SelectManufacturer from "@/view/components/SelectManufacturer";
import { map } from "lodash";
import moment from "moment";
import Dialog from "@/view/components/Dialog";
export default {
	name: "inventory-create",
	title: "Create Perishables",
	data() {
		return {
			deleteDialog: false,
			deleteButton: false,
			deleteRow:0,
			deleteIndex:0,
			// deleteUUID: null,
			// deleteURL: null,
			//deleteText: null,
			//suppliersLiting:[],
			createDialog: true,
			statusapprove: null,
			serialNumberError: null,
			uuid: null,
			barcode: null,
			minDate: moment().format("YYYY-MM-DD"),
			formValid: true,
			pageLoading: false,
			manageCategoryDialog: false,
			groupList: [],
			subGroupList: [],
			allSubGroupList: [],
			vendorList: [],
			locationList: [],
			currencyList: ["SGD", "MYR", "USD", "AUD"],
			stockThresholds: [
				{
					id: null,
					location: null,
					low_stock: null,
					excess_stock: null,
				},
			],
			inventory: {
				product_type: "piping",
				barcode: null,
				name: null,
				serial_number: null,
				quote_ref_no: null,
				description: null,
				remark: null,
				material_standard: null,
				diamension_standard: null,
				reorder_level: null,
				unit: null,
				manufacturer: null,
				group: null,
				initial_stock: 0,
				unit_price_currency: "SGD",
				unit_price: null,
				selling_cost: null,
				discount_price: null,
				effective_from: null,
				weight: null,
				length: null,
				image: null,

				diameter: null,
				reduced_diameter: null,

				width: "",
				height: "",
			},
			suppliers: [
				/* {
					id: null,
					uuid: null,
					supplier_id: null,
					part_number: null,
					price: null,
				}, */
			],
			priceList: [
				{
					id: null,
					unit_price: 0,
					selling_price: 0,
					discount_price: 0,
					start_date: moment().format("YYYY-MM-DD"),
					end_date: null,
				},
			],
			productTypeList: ["Piping", "Other Items"],
		};
	},
	components: {
		CreateDialog,
		TextInput,
		PhoneInput,
		PriceInput,
		Dialog,
		QuantityInput,
		ImageUpload,
		TooltipQuestion,
		TextAreaInput,
		AutoCompleteInput,
		DatePicker,
		DatePicker2,
		ManageCategory,
		SelectUnit,
		SelectManufacturer,
	},
	methods: {
		deleteClear() {
			this.deleteDialog = false;
			// this.deleteUUID = null;
			// this.deleteURL = null;
			 this.deleteText = null;
		},
		deleteConfirm( index,row ) {
			if(row.id){
				this.deleteIndex = index;
				this.deleteRow = row;
				this.deleteDialog = true;
			}else{
				this.suppliers.splice(index, 1);
			}
		},

		discountPriceChange(index) {
			if (this.priceList[index].discount_price > this.priceList[index].selling_price) {
				this.priceList[index].discount_price = this.priceList[index].selling_price;
			}
		},

		getCategories(param) {
			this.groupList = param;
			this.inventory.group = null;
		},
		addLocation() {
			this.stockThresholds.push({
				id: null,
				location: null,
				low_stock: null,
				excess_stock: null,
			});
		},
		removeLocation(index) {
			this.stockThresholds.splice(index, 1);
		},
		addSupplier() {
			this.suppliers.push({
				id: null,
				supplier_id: null,
				part_number: null,
				price: null,
			});
		},
		removeSupplier(index, data) {
			if (data.id) {
				const param = { id: data.id };
				console.log(param);
				RemoveSuppliers(this.uuid, param).then(() => {
					this.suppliers.splice(index, 1);
				});
				this.deleteDialog = false;
			} else {
				this.suppliers.splice(index, 1);
			}
		},
		addPrice() {
			let previous_end_date = null; // moment().format("YYYY-MM-DD");
			if (this.priceList.length < 1) {
				previous_end_date = moment().format("YYYY-MM-DD");
			}
			this.priceList.push({
				id: null,
				unit_price: 0,
				selling_price: 0,
				discount_price: 0,
				start_date: previous_end_date,
				end_date: null,
			});
		},
		removePrice(index) {
			if (index >= 1) {
				this.priceList[index - 1].end_date = null;
			}
			this.priceList.splice(index, 1);
		},
		updateStartDate(/* date, index */) {
			/* if (this.priceList.length > index + 1) {
				this.$nextTick(() => {
					this.priceList[index + 1].start_date = date;
				});
			} */
		},
		async updateOrCreate(statusproduct) {
			const _this = this;
			const formErrors = _this.validateForm(_this.$refs.inventoryForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.inventoryForm.validate()) {
				return false;
			}
			const _price_log = map(this.priceList, (row) => {
				return {
					id: row.id ? row.id : null,
					unit_price: row.unit_price ? row.unit_price : 0,
					selling_price: row.selling_price ? row.selling_price : 0,
					discount_price: row.discount_price ? row.discount_price : 0,
					start_date: row.start_date,
					end_date: row.end_date,
				};
			});

			const formData = {
				product_type: this.inventory.product_type,
				barcode: null,
				statusproduct,
				name: this.inventory.name,
				serial_number: this.inventory.serial_number,
				quote_ref_no: this.inventory.quote_ref_no,
				description: this.inventory.description,
				remark: this.inventory.remark,
				material_standard: this.inventory.material_standard,
				diamension_standard: this.inventory.diamension_standard,
				reorder_level: this.inventory.reorder_level,
				unit: this.inventory.unit,
				manufacturer: this.inventory.manufacturer,
				category: this.inventory.group,
				initial_stock: this.inventory.initial_stock,
				unit_price_currency: this.inventory.unit_price_currency,
				unit_price: this.inventory.unit_price,
				selling_cost: this.inventory.selling_cost,
				discount_price: this.inventory.discount_price,
				effective_from: this.inventory.effective_from,
				weight: this.inventory.weight,
				length: this.inventory.length,
				image: this.inventory.image,
				diameter: this.inventory.diameter,
				reduced_diameter: this.inventory.reduced_diameter,
				width: this.inventory.width,
				height: this.inventory.height,
				suppliers: this.suppliers,
				price_log: _price_log,
			};

			try {
				_this.pageLoading = true;
				if (_this.uuid) {
					const { uuid } = await UpdateInventory(_this.uuid, formData);

					_this.$router.replace({
						name: "product-detail",
						params: { uuid },
						query: { t: new Date().getTime() },
					});

					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Product has been updated." },
					]);
					_this.$store.dispatch(SET_LOCAL_DB);
					_this.clearLocalData();
				} else {
					const { uuid } = await CreateInventory(formData);

					_this.$router.replace({
						name: "product-detail",
						params: { uuid },
						query: { t: new Date().getTime() },
					});
					//console.log(uuid)
					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Product has been created." },
					]);
					_this.$store.dispatch(SET_LOCAL_DB);
					// clearing local storage data
					// deleteData("create_product");
					// deleteData("create_product_supplier");
					_this.clearLocalData();
				}
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
		setDefaultLocation(param) {
			this.stockThresholds[0].location = param;
		},
		getSubGroup(param) {
			this.subGroupList = filter(this.allSubGroupList, { group: param });
		},
		clearLocalData() {
			deleteData("create_product");
			deleteData("create_product_supplier");
		},
		getInventory(uuid) {
			GetInventory(uuid)
				.then((data) => {
					this.barcode = data.barcode;
					this.inventory.barcode = data.barcode;
					this.inventory.product_type = data.product_type;
					this.inventory.name = data.name;
					this.inventory.serial_number = data.serial_number;

					this.inventory.group = data.group;
					this.inventory.quote_ref_no = data.quote_ref_no;
					this.inventory.remark = data.remark;
					this.inventory.description = data.description;
					this.inventory.material_standard = data.material_standard;
					this.inventory.diamension_standard = data.diamension_standard;
					this.inventory.reorder_level = data.reorder_quantity;
					this.inventory.unit = data.unit;
					this.inventory.manufacturer = data.manufacturer;
					this.inventory.discount_price = data.discount_price;
					this.inventory.effective_from = data.effective_from;

					this.inventory.vendor = data.supplier;

					this.inventory.initial_stock = data.initial_stock;
					this.inventory.unit_price_currency = data.unit_price_currency;
					this.inventory.unit_price = data.unit_price;
					this.inventory.selling_cost = data.selling_cost;
					this.inventory.weight = data.weight;
					this.inventory.length = data.length;
					this.inventory.diameter = data.diameter;
					this.inventory.reduced_diameter = data.reduced_diameter;
					this.inventory.width = data.width;
					this.inventory.height = data.height;
					this.inventory.image = data.image;

					this.priceList = data.pricing_logs ? data.pricing_logs : [];

					if (data.supplier_relation && data.supplier_relation.length) {
						this.suppliers = data.supplier_relation.map((row) => {
							return {
								id: row.id,
								supplier_id: row.supplier,
								part_number: row.part_number,
								price: row.price,
							};
						});
					}

					this.$nextTick(() => {
						if (this.inventory.location) {
							this.setDefaultLocation(this.inventory.location);
						}
						if (this.inventory.group) {
							this.getSubGroup(this.inventory.group);
						}
					});
					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Product", disabled: true },
						{ text: "Update", disabled: true },
						{ text: this.barcode, disabled: true },
					]);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		validateSerialNumber(serial_number, type) {
			const _this = this;
			const params = {
				type: type,
				serial_number: serial_number,
			};
			return new Promise((resolve, reject) => {
				_this.pageLoading = true;
				_this.serialNumberError = null;
				ValidateSerialNumber(`validate-serialnumber`, params)
					.then((output) => {
						if (output.exists) {
							_this.serialNumberError = `${serial_number} already exists`;
							resolve(true);
						} else {
							resolve(false);
						}
					})
					.catch((error) => {
						reject(error);
					})
					.finally(() => {
						_this.pageLoading = false;
					});
			});
		},
		/* validateSupplerExistence(id, index) {
			let exits_count = 0;
			this.suppliers.forEach((row) => {
				if (row.supplier_id == id) {
					exits_count = exits_count + 1;
				}
			});
			if (exits_count == 2) {
				const { text } = this.vendorList.find((row) => row.value == id);
				this.$store.commit(SET_ERROR, [
					{ model: true, message: `Supplier ${text} - already selected.` },
				]);
				this.suppliers[index].supplier_id = null;
			}
		}, */
		async getSerialNumber() {
			const _this = this;
			_this.serialNumberError = null;
			let type = _this.inventory.product_type == "piping" ? "piping" : "other-items";
			const { barcode } = await GenrateSerialNumber(type);
			_this.inventory.serial_number = barcode;
		},
		pageTitle() {
			if (this.uuid) {
				return "Update Product";
			}
			return "Create new Product";
		},
		updateConfig() {
			this.$store.dispatch();
		},
		savetoLocal() {
			if (!this.uuid) {
				const create_product = {
					product_type: this.inventory.product_type,
					/* barcode: this.inventory.barcode, */
					name: this.inventory.name,
					quote_ref_no: this.inventory.quote_ref_no,
					description: this.inventory.description,
					material_standard: this.inventory.material_standard,
					diamension_standard: this.inventory.diamension_standard,
					reorder_level: this.inventory.reorder_level,
					unit: this.inventory.unit,
					manufacturer: this.inventory.manufacturer,
					group: this.inventory.group,
					initial_stock: this.inventory.initial_stock,
					unit_price_currency: this.inventory.unit_price_currency,
					unit_price: this.inventory.unit_price,
					selling_cost: this.inventory.selling_cost,
					weight: this.inventory.weight,
					length: this.inventory.length,
					diameter: this.inventory.diameter,
					reduced_diameter: this.inventory.reduced_diameter,
					height: this.inventory.height,
					width: this.inventory.width,
				};
				saveData("create_product", create_product);
			}
		},
		saveSupplierToLocal() {
			const create_product_supplier = this.suppliers;
			saveData("create_product_supplier", create_product_supplier);
		},
		endMinDate(date, index) {
			// let _priceList = cloneDeep(this.priceList);
			// const min_end_date = _priceList[index].start_date;
			const length = this.priceList.length;
			console.log({ length });
			if (index > 0) {
				this.priceList[index - 1].end_date = date;
				if (length > index + 1) {
					const minDate = this.priceList[index + 1].satrt_date;
					return minDate;
				}
			}
			return moment().format("YYYY-MM-DD");
		},
		/* startMinDate() {
			return function (index) {
				let _priceList = cloneDeep(this.priceList);
				if (_priceList.length > 1 && index >= 1) {
					const min_start_date = _priceList[index - 1].end_date;
					return min_start_date;
				}
				return moment().format("YYYY-MM-DD");
			};
		}, 
		endMinDate: () => {
			return function (index) {
				let _priceList = cloneDeep(this.priceList);
				const min_end_date = _priceList[index].start_date;
				if (min_end_date && index > 0) {
					return min_end_date;
				}
				return moment().format("YYYY-MM-DD");
			};
		},
		*/
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
		supplierList: () => {
			return function (id) {
				let _vendorList = cloneDeep(this.vendorList);
				const newArray = filter(_vendorList, (row) => {
					if (row.value != id) {
						if (!find(this.suppliers, (inner_row) => inner_row.supplier_id == row.value)) {
							return row;
						}
					} else {
						return row;
					}
				});
				return newArray;
			};
		},
		end_min_date: () => {
			return function (date, index) {
				let _priceList = cloneDeep(this.priceList);
				if (index > 0) {
					const min_end_date = _priceList[index - 1].start_date;
					this.priceList[index - 1].end_date = date;
					return min_end_date;
				}
				return moment().format("YYYY-MM-DD");
			};
		},
		isDisable() {
			const exists = this.priceList.find((row) => {
				return !row.start_date;
			});
			return exists ? true : false;
		},
		isStartDateDisable: () => {
			return function (index) {
				if (this.priceList.length == index + 1) {
					return false;
				}
				return true;
			};
		},
	},
	mounted() {
		this.groupList = this.localDB("groups", []);
		this.allSubGroupList = this.localDB("sub_groups", []);
		this.vendorList = this.localDB("suppliers", []);
		this.locationList = this.localDB("locations", []);

		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Product", disabled: true },
			{ text: "Create", disabled: true },
		]);
		let localSaveProduct = getData("create_product");
		let localSaveSupplier = getData("create_product_supplier");
		if (localSaveSupplier && localSaveSupplier.length) {
			this.suppliers = localSaveSupplier;
		}
		if (localSaveProduct) {
			this.inventory.product_type = localSaveProduct.product_type;
			this.inventory.barcode = localSaveProduct.barcode;
			this.inventory.name = localSaveProduct.name;
			this.inventory.quote_ref_no = localSaveProduct.quote_ref_no;
			this.inventory.description = localSaveProduct.description;
			this.inventory.category = localSaveProduct.category;
			this.inventory.material_standard = localSaveProduct.material_standard;
			this.inventory.diamension_standard = localSaveProduct.diamension_standard;
			this.inventory.reorder_level = localSaveProduct.reorder_level;
			this.inventory.unit = localSaveProduct.unit;
			this.inventory.manufacturer = localSaveProduct.manufacturer;
			this.inventory.group = localSaveProduct.group;
			this.inventory.initial_stock = localSaveProduct.initial_stock;
			this.inventory.unit_price_currency = localSaveProduct.unit_price_currency;
			this.inventory.unit_price = localSaveProduct.unit_price;
			this.inventory.selling_cost = localSaveProduct.selling_cost;
			this.inventory.weight = localSaveProduct.weight;
			this.inventory.length = localSaveProduct.length;
			this.inventory.diameter = localSaveProduct.diameter;
			this.inventory.reduced_diameter = localSaveProduct.reduced_diameter;
			this.inventory.diameter = localSaveProduct.diameter;
			this.inventory.reduced_diameter = localSaveProduct.reduced_diameter;
			this.inventory.height = localSaveProduct.height;
			this.inventory.width = localSaveProduct.width;
		}

		this.statusapprove = this.$route.query.status;
		const { name, params, query } = this.$route;
		if (query && query.supplier) {
			this.inventory.vendor = query.supplier;
		}
		if (query && query.group) {
			this.inventory.group = query.group;
		}
		if (name === "product-update") {
			this.uuid = params.uuid;
			if (!this.uuid) {
				this.goBack();
			}
			this.pageLoading = true;
			this.getInventory(this.uuid);
		} else {
			this.genrateBarcode("product").then((output) => {
				this.inventory.barcode = output;
			});
			this.getSerialNumber();
		}
		if (query && query.duplicate) {
			this.getInventory(query.duplicate);
		}
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
